import React, { createContext, useContext, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import { bakerIdVar } from 'web/App'
import { BannerProvider } from 'web/contexts/AnnouncementBannerContext'
import { FetchError } from 'shared/components/FetchError'
import { Spinner } from 'shared/components/Spinner'
import { ThemeProvider } from 'web/contexts/ThemeContext'
import { useBakesyQuery } from 'shared/hooks/useBakesyQuery'

export const BakeryContext = createContext()

export const BakeryProvider = ({ children, query }) => {
  const { slug, productSlug } = useParams()

  const {
    data: bakery,
    loading,
    error,
    refetch,
  } = useBakesyQuery(query, {
    fetchPolicy: 'cache-first',
    transform: (data) => data?.bakery,
    skip: !slug,
    variables: {
      slug,
      bakerySlug: slug,
      productSlug,
      visit: true,
    },
  })

  useEffect(() => {
    bakerIdVar(bakery?.user?.id)
  }, [bakery?.user?.id])

  if (loading) return <Spinner page />
  if (error) return <FetchError page onRetry={refetch} message={error.message} />

  return (
    <BakeryContext.Provider value={{ bakery }}>
      <ThemeProvider
        buttonPreferencesId={bakery.buttonPreferences?.id}
        premiumThemeId={bakery.premiumTheme?.id}
        storefrontAppearanceId={bakery.storefrontAppearance?.id}
      >
        <BannerProvider
          bannerId={bakery.announcementBanner?.id}
          enableAnnouncementBanner={bakery.enableAnnouncementBanner}
        >
          {children}
        </BannerProvider>
      </ThemeProvider>
    </BakeryContext.Provider>
  )
}

export const useBakeryContext = () => useContext(BakeryContext)

export default BakeryContext
