import React, { createContext, useContext } from 'react'
import { useFragment } from '@apollo/client'

import {
  ButtonPreferencesFragment,
  PremiumThemeFragment,
  StorefrontAppearanceFragment,
} from 'shared/graphql/fragments'
import { Colors } from 'shared/styles/Colors'
import { Fonts } from 'shared/styles/Typography'

const ThemeContext = createContext()

const { defaultPrimary, defaultBackground } = Colors
const defaults = {
  bakery: {
    buttonPreferences: {
      actionButtonName: 'Request Order',
      availabilityHidden: false,
      storefrontActionHidden: false,
    },
    premiumTheme: {
      dividerVariant: 0,
      buttonVariant: 'outline100',
      primaryFont: Fonts.DefaultPrimary,
      secondaryFont: Fonts.DefaultSecondary,
      facebookHandle: null,
      instagramHandle: null,
      tiktokHandle: null,
    },
    storefrontAppearance: { primaryColor: defaultPrimary, background: defaultBackground },
  },
}

export const ThemeProvider = ({
  children,
  buttonPreferencesId,
  premiumThemeId,
  storefrontAppearanceId,
}) => {
  let { data: storefrontAppearance = defaults.bakery.storefrontAppearance } = useFragment({
    fragment: StorefrontAppearanceFragment,
    fragmentName: 'StorefrontAppearance',
    from: {
      __typename: 'StorefrontAppearance',
      id: storefrontAppearanceId,
    },
  })

  let { data: premiumTheme = defaults.bakery.premiumTheme } = useFragment({
    fragment: PremiumThemeFragment,
    fragmentName: 'PremiumTheme',
    from: premiumThemeId
      ? {
          __typename: 'PremiumTheme',
          id: premiumThemeId,
        }
      : null,
  })

  let { data: buttonPreferences = defaults.bakery.buttonPreferences } = useFragment({
    fragment: ButtonPreferencesFragment,
    fragmentName: 'ButtonPreferences',
    from: buttonPreferencesId
      ? {
          __typename: 'ButtonPreferences',
          id: buttonPreferencesId,
        }
      : null,
  })

  buttonPreferences = {
    ...defaults.bakery.buttonPreferences,
    ...buttonPreferences,
  }

  storefrontAppearance = {
    ...defaults.bakery.storefrontAppearance,
    ...storefrontAppearance,
  }

  premiumTheme = {
    ...defaults.bakery.premiumTheme,
    ...premiumTheme,
  }

  const { availabilityHidden, actionButtonName, storefrontActionHidden } =
    buttonPreferences || defaults.bakery.buttonPreferences
  const { image: coverImage, primaryColor, background: backgroundColor } = storefrontAppearance

  const {
    dividerVariant,
    buttonVariant,
    // We don't need to add fallback to primaryFont here because it will break
    // font size adjustment
    primaryFont,
    secondaryFont: _secondaryFont,
    facebookHandle,
    instagramHandle,
    tiktokHandle,
  } = premiumTheme

  const secondaryFont = _secondaryFont.includes('Carlito')
    ? Fonts.DefaultSecondary
    : `${_secondaryFont}, ${Fonts.Fallback}`

  const theme = {
    dividerVariant,
    buttonVariant,
    primaryFont,
    secondaryFont,
    secondaryFontRaw: _secondaryFont,
    facebookHandle,
    instagramHandle,
    tiktokHandle,
    coverImage,
    primaryColor,
    backgroundColor,
    availabilityHidden,
    actionButtonName,
    storefrontActionHidden,
  }

  return <ThemeContext.Provider value={theme}>{children}</ThemeContext.Provider>
}

export const useThemeContext = () => useContext(ThemeContext)
export const SessionConsumer = ThemeContext.Consumer
