import { useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'

import { useQuestionCounter } from 'web/hooks/useQuestionCounter'

export const useOrderBuild = ({ order }) => {
  const location = useLocation()
  const currentPage = location.pathname.split('/').pop()
  const { enableOrderSpecifications } = order?.bakery || {}
  const { orderItems = [] } = order || {}

  const { skipDueDate, skipInspirationPhotos } = useMemo(() => {
    return {
      skipDueDate: orderItems.every((oi) => oi.offering.dueDateDisabled),
      skipInspirationPhotos: orderItems.every((oi) => !oi.offering.enableInspirationPhotos),
    }
  }, [orderItems])

  const [orderOfferingIds, orderCategoryIds] = useMemo(() => {
    const offeringIds = []
    const categoryIds = []

    orderItems.forEach((oi) => {
      offeringIds.push(oi.offeringId)
      categoryIds.push(oi.categoryId)
    })
    return [new Set(offeringIds), new Set(categoryIds)]
  }, [orderItems])

  const forOfferings = useCallback(
    (offerings) => {
      if (!offerings.length) return

      const offeringIds = offerings.map((o) => o.id)
      return offeringIds.some((id) => orderOfferingIds.has(id))
    },
    [orderOfferingIds],
  )

  const forCategories = useCallback(
    (categories) => {
      if (!categories.length) return

      const categoryIds = categories.map((c) => c.id)
      return categoryIds.some((id) => orderCategoryIds.has(id))
    },
    [orderCategoryIds],
  )

  const customQuestions = useMemo(() => {
    const items = order.bakery?.customQuestions

    if (!items?.length || currentPage === 'details') return []

    return items.reduce((acc, cq) => {
      const withOfferings = forOfferings(cq.offerings) || forCategories(cq.categories)

      // If custom question has no categories and offerings or has offerings but not applied separately
      // it means that either it's a general question or that it's for other category/offering not in the Order
      if (
        ((cq.offerings.length === 0 && cq.categories.length === 0) || withOfferings) &&
        !cq.applySeparately
      ) {
        acc.push(cq)
      }

      if (withOfferings && cq.applySeparately) {
        // here we're building array of order items that we need to show questions for
        const questionOfferings = orderItems.reduce((oiAcc, oi) => {
          if (cq.categories.some((cc) => cc.id === oi.categoryId)) oiAcc.push(oi)
          if (cq.offerings.some((co) => co.id === oi.offeringId)) oiAcc.push(oi)
          return oiAcc
        }, [])

        questionOfferings.forEach((_, idx) => acc.push({ ...cq, orderItemIdx: idx }))
      }

      return acc
    }, [])
  }, [order, currentPage, forOfferings, forCategories, orderItems])

  const customQuestionsLength = customQuestions.length
  const dueDateOffset = skipDueDate ? 0 : 1
  const specificationsOffset = enableOrderSpecifications ? 0 : 1
  const inspirationalPhotosOffset = skipInspirationPhotos ? 1 : 0

  const { questionCounter, setQuestionCounter } = useQuestionCounter({
    customQuestionsLength,
    dueDateOffset,
    specificationsOffset,
    currentPage,
    inspirationalPhotosOffset,
  })

  return {
    currentPage,
    customQuestions,
    loading: order?.bakery?.status === 'expired',
    order,
    questionCounter,
    setQuestionCounter,
    skipDueDate,
  }
}
