import React, { useState } from 'react'
import styled from 'styled-components'
import { gql, useMutation } from '@apollo/client'
import { useParams } from 'react-router-dom'

import { Colors } from 'shared/styles/Colors'
import { ErrorMessage } from 'shared/components/Form'
import { FixedWidthContainer, MediaQueries } from 'web/styles/responsive'
import { TextField as FormTextField, StyledAutoResizeTextArea } from 'web/components/form'
import { Header } from 'web/pages/OrderRequest/styles'
import { ReviewStars } from 'web/components/reviews/ReviewStars'

export const ReviewFormComponent = ({ customerName, onSuccess }) => {
  const { orderNumber } = useParams()
  const [createReview] = useMutation(CREATE_REVIEW)

  const [rating, setRating] = useState()
  const [name, setName] = useState(customerName || '')
  const [title, setTitle] = useState('')
  const [comments, setComments] = useState('')
  const [processing, setProcessing] = useState(false)
  const [error, setError] = useState(null)

  const functions = {
    name: setName,
    title: setTitle,
    comments: setComments,
  }

  const handleChange =
    (field) =>
    ({ target: { value } }) => {
      functions[field](value)
    }

  const handleSubmit = async () => {
    setProcessing(true)
    try {
      const {
        data: {
          createReview: { errors },
        },
      } = await createReview({
        variables: {
          input: {
            comments,
            name,
            orderNumber,
            rating,
            title,
          },
        },
      })

      if (errors.length) throw errors[0]

      onSuccess()
    } catch (e) {
      console.error(e.messages)
      setError(e.messages[0])
    }
    setProcessing(false)
  }

  const disabled = () => {
    const dflt = processing || !rating

    if (!comments) return dflt
    else return !name || !title || dflt
  }

  return (
    <Container>
      <div>
        <StarsContainer>
          <HeaderTitle>Tap a Star to Rate</HeaderTitle>
          <ReviewStars
            rating={rating}
            onStarClick={setRating}
            type="reviews"
            containerStyle={{ justifyContent: 'center' }}
          />
        </StarsContainer>
        <Notice>Fill out the rest to leave a review (optional)</Notice>

        <InputsContainer>
          <TextField type="text" placeholder="Name" value={name} onChange={handleChange('name')} />
          <TextField
            type="text"
            placeholder="Review Title"
            value={title}
            onChange={handleChange('title')}
          />
          <TextArea
            type="text"
            placeholder="Review"
            value={comments}
            onChange={handleChange('comments')}
            rows={2}
          />
        </InputsContainer>
      </div>

      {!!error && <ErrorMessage error={error} />}

      <SubmitButtonContainer>
        <SubmitButton onClick={handleSubmit} disabled={disabled()} type="button">
          Submit
        </SubmitButton>
      </SubmitButtonContainer>
    </Container>
  )
}

const Container = styled(FixedWidthContainer)({
  paddingTop: 40,
  paddingBottom: 40,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const HeaderTitle = styled(Header)({
  fontSize: '2.5rem',
})

const StarsContainer = styled.div({
  marginBottom: 20,
})

const InputsContainer = styled.div({ marginBottom: 20 })

const SubmitButtonContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

const SubmitButton = styled.button({
  border: 'none',
  backgroundColor: Colors.brand,
  color: Colors.white,
  padding: '10px 16px',
  borderRadius: '4px',
  width: '50%',
  cursor: 'pointer',
  fontSize: 20,

  '&:disabled, &[disabled]': {
    backgroundColor: Colors.brandFaded,
  },

  [`@media ${MediaQueries.mdUp}`]: {
    width: '40%',
  },
})

const TextField = styled(FormTextField)({
  '&, &:focus': {
    borderWidth: '0 0 1px 0',
  },

  borderBottom: `1px solid ${Colors.grey25}`,

  '&:focus': {
    borderBottom: `1px solid ${Colors.brand}`,
  },
})

const TextArea = styled(StyledAutoResizeTextArea)({
  border: 'none',
  borderBottom: `1px solid ${Colors.grey25}`,
})

const Notice = styled.p({
  textAlign: 'center',
})

const CREATE_REVIEW = gql`
  mutation CreateReview($input: CreateReviewInput!) {
    createReview(input: $input) {
      errors {
        messages
      }
    }
  }
`
