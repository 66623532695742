import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  categories: [],
}

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    addCategory: (state, action) => {
      state.categories.push(action.payload)
    },
    removeCategory: (state, action) => {
      const defaultIndex = state.categories.findIndex((category) => category.default)
      const index = state.categories.findIndex((category) => category.id === action.payload)

      const defaultCategory = state.categories[defaultIndex]
      const category = state.categories[index]

      defaultCategory.offerings = [...category.offerings, ...defaultCategory.offerings]
      defaultCategory.offeringsCount = defaultCategory.offerings.length
      if (index > -1) state.categories.splice(index, 1)
    },
    replaceCategory: (state, action) => {
      const index = state.categories.findIndex((category) => category.id === action.payload.id)
      if (index > -1) state.categories[index] = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    updateCategoryOffering: (state, action) => {
      const { offering, updatedOffering } = action.payload
      const categories = state.categories

      /** Add the updated offering to the category */
      const offeringCategoryIdx = categories.findIndex(
        (cat) => cat.id === updatedOffering.categoryId,
      )

      if (offeringCategoryIdx < 0) return null

      const categoryOfferings = [...categories[offeringCategoryIdx].offerings]
      const offeringIdx = categoryOfferings.findIndex((off) => off.id === updatedOffering.id)

      if (offeringIdx >= 0) categoryOfferings[offeringIdx] = updatedOffering
      else categoryOfferings.push(updatedOffering)

      state.categories[offeringCategoryIdx].offerings = categoryOfferings
      state.categories[offeringCategoryIdx].offeringsCount = categoryOfferings.length

      /** Remove the offering from the previous category */
      const previousCategoryIdx = categories.findIndex((cat) => cat.id === offering.categoryId)

      if (previousCategoryIdx < 0) return null

      const previousCategoryOfferings = [...categories[previousCategoryIdx].offerings]
      const previousOfferingIdx = previousCategoryOfferings.findIndex(
        (off) => off.id === offering.id,
      )

      if (previousOfferingIdx >= 0) previousCategoryOfferings.splice(previousOfferingIdx, 1)

      state.categories[previousCategoryIdx].offerings = previousCategoryOfferings
      state.categories[previousCategoryIdx].offeringsCount = previousCategoryOfferings.length
    },
  },
})

export const {
  addCategory,
  removeCategory,
  replaceCategory,
  setCategories,
  updateCategoryOffering,
} = categoriesSlice.actions

export const categoriesReducer = categoriesSlice.reducer
