import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { PAGES } from './constants'
import { PlatformStorage } from 'shared/services/PlatformStorage'
import { RequestOrderDocument } from 'shared/graphql/mutations'
import { Spinner } from 'shared/components/Spinner'
import { StorageKeys } from 'shared/constants/constants'

export const NewOrderRequestPage = () => {
  const [order, setOrder] = useState()

  const { bakeryId } = useParams()
  const { state } = useLocation()

  const completionDate = state?.completionDate

  useEffect(() => {
    if (completionDate) PlatformStorage.setItem(StorageKeys.completionDate, completionDate)
  }, [completionDate])

  const [requestOrder] = useMutation(RequestOrderDocument, {
    onCompleted: (data) => {
      setOrder(data.requestOrder.order)
    },
  })

  useEffect(() => {
    requestOrder({
      variables: {
        input: {
          bakeryId,
        },
      },
    })
  }, [bakeryId, requestOrder])

  if (order) return <Navigate to={`/order-request/${order.id}/${PAGES[0].name}`} state={state} />
  return <Spinner page />
}
