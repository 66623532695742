import { useMemo } from 'react'

/**
 * this hook returns an object with offerings availability data
 * conposed in a certain way:
 * byDate - object that has all of the offerings' availability preferences sorted by date
 * used to check if the date should be blocked or not
 * byOrderItem - object with order item slugs as keys with
 * availability preferences as values
 */

export const useOfferingsAvailability = (orderItems) => {
  /* NOTE: treat ampty preference [] array as no preferences */
  return useMemo(() => {
    if (
      orderItems.some(
        (oi) =>
          !oi.offering.availabilityPreferences?.preference ||
          !oi.offering.availabilityPreferences?.preference.length,
      )
    )
      return { byDate: {}, byOrderItem: {} }

    return orderItems.reduce(
      (acc, { offering: { availabilityPreferences, slug } }) => {
        if (!availabilityPreferences?.preference || !availabilityPreferences?.preference.length)
          return acc

        const byDate = {}

        availabilityPreferences.preference.forEach((pref) => {
          if (byDate[pref]) byDate[pref] = [...byDate[pref], slug]
          else byDate[pref] = [slug]
        })

        acc = {
          byDate: { ...acc.byDate, ...byDate },
          byOrderItem: { ...acc.byOrderItem, [slug]: availabilityPreferences.preference },
        }

        return acc
      },
      { byDate: {}, byOrderItem: [] },
    )
  }, [orderItems])
}
